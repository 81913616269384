import React from "react";
import styles from "./Badge.module.css";

const Badge: React.FC<{ className: string; number?: number }> = ({
  className,
  number = 0,
}) => {
  return (
    <div className={`${styles.component} ${className}`}>
      <div className={styles.element}>{number}</div>
    </div>
  );
};

export default Badge;
