export enum STATUS {
  New = "1 New",
  Modified = "2 Modified",
  Emailed = "3 Emailed",
  Closed = "4 Closed",
}

export interface EmailData {
  id?: string;
  bookingRef: string;
  customerProblem: string;
  departureAirport: string;
  destinationAirport: string;
  easyJetEmployees: string;
  flightNumber: string;
  issueType: string;
  lastModified: string;
  piiSummary: string;
  status: STATUS;
  subject: string;
  emails: (CustomerEmailData | CustomerSupportEmailData)[];
}

export interface CustomerEmailData {
  body: string;
  date: string;
  sender: string;
  suggestedRemediation: string;
  suggestedResponse: string;
  summary: string;
}

export interface CustomerSupportEmailData {
  body: string;
  date: string;
  sender: string;
  title: string;
}
