import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
// import IconButton from "@mui/material/IconButton";
// import CommentIcon from "@mui/icons-material/Comment";
interface CheckBoxList {
  list: string[];
  onChange: (checked: string[]) => void;
}
const CheckboxList: React.FC<CheckBoxList> = ({ list, onChange }) => {
  const [checked, setChecked] = React.useState([0]);

  React.useEffect(() => {
    const checkedItems = list.filter((item, index) => {
      return checked.includes(index);
    });
    onChange(checkedItems);
  }, [checked, list]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  return (
    <List sx={{ width: "100%" }}>
      {list.map((item, index) => {
        const labelId = `checkbox-list-label-${index}`;

        return (
          <ListItem
            key={index}
            // secondaryAction={
            //   <IconButton edge="end" aria-label="comments">
            //     <CommentIcon />
            //   </IconButton>
            // }
            disablePadding
          >
            <ListItemButton
              role={undefined}
              onClick={handleToggle(index)}
              dense
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(index) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item} />
            </ListItemButton>
          </ListItem>
        );
      })}
      {/* <ListItem
        key={index}
        // secondaryAction={
        //   <IconButton edge="end" aria-label="comments">
        //     <CommentIcon />
        //   </IconButton>
        // }
        disablePadding
      >
        <ListItemButton role={undefined} onClick={handleToggle(index)} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked.indexOf(index) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
            />
          </ListItemIcon>
          <ListItemText id={labelId} primary={item} />
        </ListItemButton>
      </ListItem> */}
    </List>
  );
};

export default CheckboxList;
