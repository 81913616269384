import { signInWithEmailAndPassword, signOut, User } from "firebase/auth";
import { auth } from "../config";

/**
 * Authenticate user with email and password
 * @param email User email
 * @param password User Password
 * @returns User information
 */
export const signIn = async (email: string, password: string): Promise<User | void> => {
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
    // Signed in
      const user = userCredential.user;
      // console.log(user);
      return user;
    })
    .catch((error) => {
    // const errorCode = error.code;
    // const errorMessage = error.message;
      console.error(error);
    });

};

/**
 * Logs out the current user
 * @returns {Promise<void>}
 */
export const logOut = async (): Promise<void> => {
  return signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
    console.error(error);
  });
};
