import { collection, getDocs, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../config";
import { EmailData } from "../../models/types";

/**
 * Fetch all the emails from firestore
 * @returns {EmailData[]} All the emails from firestore
 */

export const fetchEmailIds = async (): Promise<EmailData[]>=> {
  const emailsCol = collection(db, "conversations");
  const emailsSnaphot = await getDocs(emailsCol);

  const emailsList = emailsSnaphot.docs.map(doc => {
    return ({
      ...doc.data() as EmailData,
      id: doc.id,
      lastModified: doc.data().lastModified?.toDate().toLocaleString("en-gb"),
      createdAt: doc.data().createdAt?.toDate().toLocaleString("en-gb"),
    });
  });
  return emailsList;
};

/**
 * Get all the information about the email thread for the given id
 * @param docId Document Id that you want to retrieve
 * @returns All of the content stored in the given document
 */
export const fetchEmail = async (docId: string): Promise<EmailData | void> => {
  const docRef = doc(db, "conversations", docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return { ...docSnap.data() as EmailData, id: docSnap.id };
  } else {
    console.log("No such document!");
    return;
  }
};

/**
 * Update the status of an email thread to newStatus
 * @param docId
 * @param newStatus
 */
export const updateEmailStatus = async (docId: string, newStatus: string) => {
  const docRef = doc(db, "conversations", docId);
  await updateDoc (docRef, {
    status: newStatus,
    lastModified: new Date()
  });
};

export default db;
