import { Routes, Route } from "react-router-dom";
import { EmailsMenuPage, MessagesPage, LoginPage } from "../pages";
import PrivateRoute from "./PrivateRoute";
import { EMAIL_ACTION_TYPE } from "../store/actions/types";

const Router = ({
  emailState,
  emailDispatch,
}: {
  emailState: { list: any[]; userLoggedIn: boolean };
  emailDispatch: React.Dispatch<{ type: EMAIL_ACTION_TYPE; payload: any }>;
}) => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <PrivateRoute emailState={emailState} emailDispatch={emailDispatch}>
            <EmailsMenuPage
              emailState={emailState}

            />
          </PrivateRoute>
        }
      />
      <Route
        path="/email/:id"
        element={
          <PrivateRoute emailState={emailState} emailDispatch={emailDispatch}>
            <MessagesPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default Router;
