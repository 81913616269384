import React, { Dispatch, useState } from "react";
import { EmailsMenu } from "../components/EmailsMenuPage";
import styles from "./EmailsMenuPage.module.css";
import { Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import EnhancedTable from "../components/General/Table/Table";
import { EMAIL_ACTION_TYPE } from "../store/actions/types";

interface Props {
  emailState: {list: any[], userLoggedIn: boolean};
}

const EmailsMenuPage = ({ emailState }: Props) => {
  const [searchParams] = useSearchParams();
  const rows = emailState.list;
  const [rowState, setRowState] = useState(rows);

  return (
    <div className={styles.container}>
      <EmailsMenu rows={rows} onSearchHandler={(rows: any[]) => {
        setRowState(rows);
      }} /> {/* filters={filters} /> */}
      <div
        className={styles["contentOuterContainer"]}
        style={{ left: "400px" }}
      >
        <div className={styles.contentInnerContainer}>
          <div className={styles.fillHeight}>
            <div className={styles.gridContainer}>
              <div
                style={{
                  padding: "10px 20px",
                  height: "-webkit-fill-available",
                }}
              >
                {/* <div className="">{rows.length} emails</div> */}
                <div className={styles.gridInnerContainer}>
                  <EnhancedTable
                    rows={rowState.length > 0 ? rowState : rows}
                    filters={Array.from(searchParams.keys())}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailsMenuPage;
