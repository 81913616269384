import React from "react";
import styles from "./SideMenu.module.css";
import ParamsContainer from "./ParamsContainer";
import ActionsContainer from "./ActionsContainer";

interface SideMenuProps {
  selectedEmail: any;
  lastUserEmail: any;
  onChange: (checked: string[]) => void;
}
const SideMenu: React.FC<SideMenuProps> = ({ selectedEmail, lastUserEmail, onChange }) => {

  return (
    <div className={styles.container}>

      <ActionsContainer onChange={onChange} suggestedRemediation={String(lastUserEmail.suggestedRemediation) || "null"} />

      <ParamsContainer
        key={selectedEmail.bookingRef}
        bookingReference={selectedEmail?.bookingRef}
        departureAirport={selectedEmail?.departureAirport}
        destinationAirport={selectedEmail?.destinationAirport}
        issueType={selectedEmail?.issueType}
        // suggestedRemediation={lastUserEmail?.suggestedRemediation || ""}
      />
    </div>
  );
};

export default SideMenu;
