import React, { useEffect, useState } from "react";
import { CheckboxList } from "../General";
import styles from "./ActionsContainer.module.css";

const parseSuggestions = (suggestions: string): string[] => {
  let remediationList = [];
  if (suggestions === "null") {
    remediationList = ["null"];
  } else {
    remediationList = suggestions.split(", ").map(rem => {
      return rem[0].toUpperCase() + rem.slice(1);
    });
  }
  return remediationList;
};

interface ActionContainerProps {
  suggestedRemediation: string,
  onChange: (checked: string[]) => void,
}

const ActionsContainer: React.FC<ActionContainerProps> = ({
  suggestedRemediation, onChange
}) => {

  const initialList = parseSuggestions(suggestedRemediation);
  const [list, setList] = useState<string[]>(initialList);

  useEffect(() => {
    setList(parseSuggestions(suggestedRemediation));
  }, [suggestedRemediation]);

  return (
    <div>
      <span className={styles.titleText}>Recommended actions</span>
      {list[0] !== "null" ? <CheckboxList onChange={onChange} list={list} /> : <p>Sorry, something went wrong.</p>}
    </div>
  );
};

export default ActionsContainer;
