import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/Email";
import { Tabs, Tab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

interface SideMenuProps {
  toggleModal: () => void;
}
const AntTabs = styled(Tabs)({
  borderRight: "1px solid #e8e8e8",
  backgroundColor: "#fafafa",
  width: "70px",
  display: "flex",
  alignContent: "center",
  "& .MuiTabs-indicator": {
    backgroundColor: "var(--main-color)",
  },
  "& .MuiTab-labelIcon": {
    minWidth: "70px",
    padding: "0px",
    fontSize: "12px",
  },
});

const SideMenu: React.FC<SideMenuProps> = ({ toggleModal }) => {
  const [value, setValue] = useState<string>("email");
  const navigate = useNavigate();
  const location = useLocation();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === "email") navigate("/");
  };

  const returnToMain = () => {
    if (location.pathname.includes("email")) {
      toggleModal();
    }
  };
  return (
    <AntTabs
      style={{ color: "var(--main-color)", overflow: "unset" }}
      value={value}
      onChange={handleChange}
      aria-label="icon tabs example"
      orientation="vertical"
      textColor="inherit"
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    >
      <Tab
        value="email"
        icon={<EmailIcon aria-label="email"></EmailIcon>}
        label="Email"
        // href="/"
        onClick={returnToMain}
      />
      {/* <Tab
        value="document"
        icon={
          <DescriptionIcon aria-label="document comparison"></DescriptionIcon>
        }
        label="Docs"
        // href="/"
      /> */}
    </AntTabs>
  );
};

export default SideMenu;
