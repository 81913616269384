import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signIn } from "../firebase/auth";
import styles from "./LoginPage.module.css";

function LoginScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  let loading = false;
  const signInUser = async () => {
    loading = true;
    signIn(email, password)
      .then((user) => {
        if (user) {
          navigate("/");
        } else {
          setError(true);
          loading = false;
        }
      })
      .catch(() => {
        setError(true);
        loading = false;
      });
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.innerContainer}>
        <label htmlFor="userEmail" className={styles.label}>
          Email
        </label>
        <input
          id="userEmail"
          type="email"
          placeholder="Email"
          value={email}
          className={styles.input}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <label htmlFor="userPassword" className={styles.label}>
          Password
        </label>
        <input
          id="userPassword"
          type="password"
          placeholder="Password"
          className={styles.input}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error ? (
          <p className={styles.error}>
            Error logging in, please check your details
          </p>
        ) : null}
        <button
          className={styles.button}
          type="button"
          onClick={signInUser}
          disabled={loading}
        >
          Sign in
        </button>
      </div>
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src="/assets/Sprint Reply-LOGO RGB.png"
          alt="reply logo"
        />
      </div>
    </div>
  );
}

export default LoginScreen;
