import React, { useState, useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import EmailsMenuItem from "./EmailsMenuItem";
import { STATUS } from "../../models/types";

import styles from "./EmailsMenu.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface EmailsMenuProps {
  rows: any[];
  onSearchHandler: (rows: any[]) => void;
}

/**
 *
 * @param s1 string from search box.
 * @param s2 String from searched item, to be compared against.
 * @returns an integer representing the similarity of the two strings. 0 is identical.
 */
const searchFor = (s1: string, s2: string) => {
  return s2.includes(s1);
};

const EmailsMenu: React.FC<EmailsMenuProps> = ({ rows, onSearchHandler }) => {
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchBy, setSearchBy] = useState<string>("reqID");
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState<Date>(new Date());

  const searchRef = useRef<HTMLInputElement>(null);

  /**
   * @private
   * @param value Filter parameter
   */
  const filterFn = (value: string): void => {
    setSearchParams((prev) => {
      if (!prev.has(value)) {
        prev.set(value, "true");
      } else prev.delete(value);
      return prev;
    });
  };

  const parseDate = (date: string): Date => {
    const actualDate = new Date();
    const noTime = date.split(", ")[0];
    const splitDate = noTime.split("/");
    actualDate.setDate(Number(splitDate[0]));
    actualDate.setMonth(Number(splitDate[1])-1);
    actualDate.setFullYear(Number(splitDate[2]));
    return actualDate;
  };

  const dateCompare = (date1: Date, date2: Date): boolean => {
    if (date1.getDate() !== date2.getDate()) {
      return false;
    }
    if (date1.getMonth() !== date2.getMonth()) {
      return false;
    }
    if (date1.getFullYear() !== date2.getFullYear()) {
      return false;
    }
    return true;
  };

  const slowSearch = async () => {
    setSearchLoading(true);
    const getEmails = (row: any) => {
      return row.emails.map((email: any) => email.body).join("\n");
    };
    rows = rows.filter((row) =>
      searchFor(searchInput.toUpperCase(), getEmails(row).toUpperCase())
    );
    onSearchHandler(rows);
    setSearchLoading(false);
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      // filter mail by search input

      const getField = (row: any) => {
        switch (searchBy) {
        case "reqID":
          return row.id;
        case "subject":
          return row.subject;
        case "dest":
          return row.destinationAirport;
        case "creation":
          return row.createdAt;
        case "modified":
          return row.lastModified;
        case "sender":
          return row.emails[0].sender;
        case "emailBody":
          return row.emails.map((email: any) => email.body).join("\n");
        }
      };

      if (searchBy === "creation" || searchBy === "modified") {
        rows = rows.filter((row) =>
          dateCompare(date, parseDate(getField(row)))
        );
      } else if (searchBy !== "emailBody" && searchInput.length > 0) {
        rows = rows.filter((row) =>
          searchFor(searchInput.toUpperCase(), getField(row).toUpperCase())
        );
      }

      onSearchHandler(rows);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [searchInput, date, searchBy]);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>Emails</div>
      <div className={styles.searchBarContainer}>
        <div className={styles.searchBox}>
          <input
            type="text"
            placeholder="Search in all emails..."
            className={styles.searchBoxInput}
            ref={searchRef}
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
          <SearchIcon className={styles.searchIcon} />
        </div>
      </div>
      {/* <div className={styles.optionsContainer}>
        <p>Search By:</p>
        <div className={styles.container}>
          {[
            ["reqID", "Request ID"],
            ["subject", "Subject"],
            ["dest", "Destination"],
            ["sender", "Sender"],
          ].map((item, index) => {
            return (
              <button
                className={styles.button}
                disabled={searchBy === item[0]}
                onClick={() => setSearchBy(item[0])}
                key={index}
              >
                {item[1]}
              </button>
            );
          })}
          <div style={{ justifyContent: "space-between", display: "flex", flexWrap: "wrap", paddingRight: 20 }}>
            <button className={styles.button} disabled={searchBy === "emailBody"} onClick={() => setSearchBy("emailBody")}>Email Body</button>
            {searchBy === "emailBody" && <button className={styles.eJButton} onClick={slowSearch}>{searchLoading ? "Loading" : "Search"}</button>}
          </div>

        </div>
        <div className={styles.container}>
          <button
            className={styles.button}
            disabled={searchBy === "creation"}
            onClick={() => setSearchBy("creation")}
          >
            Creation Date
          </button>
          <button
            className={styles.button}
            disabled={searchBy === "modified"}
            onClick={() => setSearchBy("modified")}
          >
            Last Modified
          </button>
          <DatePicker
            selected={date}
            className={styles.datePicker}
            dateFormat={"dd/MM/yyyy"}
            onChange={(e) => setDate(e ? e : new Date())}
          />
        </div>
      </div> */}

      <div className={styles.optionsContainer}>
        <div className={styles.textContainer}>
          <div className={styles.text}>All emails</div>
        </div>
        <div className={styles.separator}>
          <div className={styles.rectangle} />
        </div>
        <div className={styles.section}>
          <EmailsMenuItem
            text="Unassigned emails"
            number={rows.filter((item) => !item.assignedTo).length}
          />
        </div>
        <div className={styles.separator}>
          <div className={styles.rectangle} />
        </div>
        <div className={styles.section}>
          {Object.entries(STATUS).map(([key]) => (
            <EmailsMenuItem
              text={key}
              key={key}
              number={rows.filter((item) => item.status.includes(key)).length}
              onClick={filterFn}
              searchParams={searchParams}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmailsMenu;
