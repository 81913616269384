import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { onAuthStateChanged } from "firebase/auth";

import { auth } from "../firebase/config";
import { Loader } from "../components/General";
import { EMAIL_ACTION_TYPE } from "../store/actions/types";

type Props = {
  children: any;
  emailState: {list: any[]; userLoggedIn: boolean};
  emailDispatch: React.Dispatch<{type: EMAIL_ACTION_TYPE, payload: any}>
};

const PrivateRoute = ({ children, emailState, emailDispatch }: Props) => {
  const userLoggedIn = emailState.userLoggedIn;
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const updateLoggedIn = (status: boolean) => {
      emailDispatch({ type: EMAIL_ACTION_TYPE.LOGIN, payload: { status: status } });
    };
    onAuthStateChanged(auth, (user) => {
      console.log("checking status");
      if (user) {
        updateLoggedIn(true);
        setLoading(false);
        console.log("logged in");
      } else {
        updateLoggedIn(false);
        setLoading(false);
        console.log("logged out");
      }
    });
  }, [userLoggedIn]);

  if (loading) {
    return <Loader />;
  }

  if (userLoggedIn) {
    // authorized so return child components
    return children;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoute;
