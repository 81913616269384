import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { RegeneratePromptType } from "../../helpers/queries";

import styles from "./SplitButton.module.css";

const SplitButton: React.FC<{
  options: string[];
  fixedText?: string;
  clickHandler: (
    event: React.MouseEvent<unknown> | null,
    type?: RegeneratePromptType
  ) => void;
  loading?: boolean;
  disabled?: boolean;
}> = ({
  options,
  fixedText,
  clickHandler,
  loading = false,
  disabled = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = () => {
    let type: RegeneratePromptType = "basic";
    switch (options[selectedIndex]) {
    case "More verbose":
      type = "more";
      break;
    case "Less verbose":
      type = "less";
      break;
    case "More formal":
      type = "formal";
      break;
    case "More informal":
      type = "informal";
      break;
    default:
      break;
    }
    clickHandler(null, type);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        disabled={disabled}
        aria-label="split button"
      >
        <Button
          onClick={handleClick}
          style={{
            borderColor: "var(--main-color)",
            fontSize: "11px",
          }}
        >
          {loading && <span className={styles["button--loading"]}></span>}
          <span
            style={{
              color: loading ? "transparent" : "inherit",
            }}
          >
            {fixedText} {options[selectedIndex]}
          </span>
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select prompt params"
          aria-haspopup="menu"
          onClick={handleToggle}
          style={{
            borderColor: "var(--main-color)",
            fontSize: "10px",
          }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      // disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default SplitButton;
