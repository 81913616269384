import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import styles from "./EmailThreadAccordion.module.css";

interface EmailThreadAccordionProps {
  emails: any[];
}
const EmailThreadAccordion: React.FC<EmailThreadAccordionProps> = ({
  emails = [],
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div className={styles.container}>
      {emails.map((email, index) => {
        if (email.body) {
          return (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                <div className={styles.summaryContainer}>
                  <Typography variant="body2">
                    {email.date
                      .toDate()
                      .toLocaleString("en-GB", { timeZone: "UTC" })}
                  </Typography>
                  <Typography>{email.sender}</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="htmlText">
                  {email?.body?.replaceAll("<br>", "\n")}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        }
        return <div key={index}></div>;
      })}
    </div>
  );
};

export default EmailThreadAccordion;
