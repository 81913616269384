import { Button } from "@mui/material";
import { Data, Order, HeadCell } from "../Table/Table.types";
import styles from "./tableHelpers.module.css";

const createData = (
  id: string,
  subject: string,
  assignedTo: string,
  status: "new" | "emailed" | "modified",
  createdAt: string,
  lastModified: string,
  edit: string
): Data => {
  return {
    id,
    subject,
    assignedTo,
    status,
    createdAt,
    lastModified,
    edit,
  };
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    numeric: false,
    align: "left",
    disablePadding: true,
    label: "Request ID",
  },
  {
    id: "subject",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Subject",
  },
  {
    id: "assignedTo",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Assigned to",
  },
  {
    id: "status",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "createdAt",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Created At",
  },
  {
    id: "lastModified",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Last modified",
  },
  {
    id: "edit",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Edit",
  },
];

const renderStatus = (value: string) => {
  // const tmp = value.split(" ")[1];
  return <div className={styles[value]}>{value}</div>;
};

const renderDetailsButton = (row: any, onClick?: (row: any) => void) => {
  return (
    <strong>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => (onClick ? onClick(row.subject) : "")}
      >
        Edit
      </Button>
    </strong>
  );
};
export {
  createData,
  getComparator,
  stableSort,
  headCells,
  renderStatus,
  renderDetailsButton,
};
